import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../containers/about-us/page-header'
import AboutArea from '../containers/about-us/about-area'
import SolutionsArea from '../containers/about-us/solutions-area'
import SectionTwo from '../containers/elements/client-logo/section-two'

const AboutPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="About Us" />
    <Header />
    <main className="site-wrapper-reveal">
      <PageHeader />
      <AboutArea />
      <SectionTwo/>
      <SolutionsArea />

    </main>
    <Footer />
  </Layout>
)

export default AboutPage
