import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import SectionTitle from '../../../components/ui/section-title'
import {AboutWrapper, LeftBox, RightBox} from './about-area.style'

const AboutDesc = ({sectionTitleStyle, markHeading, textStyle, descStyle}) => {
    const AboutDescData = useStaticQuery(graphql `
        query AboutPageDescQuery {
            aboutUs(id: {eq: "about-page-intro"}) {
                section_title {
                  title
                  subtitle
                }
                heading
                mark
                text
                desc
              }
        } 
    `);
    const {section_title: {title, subtitle}, heading, mark, text, desc} = AboutDescData.aboutUs;

    return (
        <AboutWrapper>
            <Container>
                <Row>
                    <Col lg={7} md={9} ml="auto" mr="auto">
                        <SectionTitle
                            {...sectionTitleStyle}
                            title={title}
                            subtitle={subtitle}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={{span: 4, offset: 1}}>
                        <LeftBox>
                            {heading && <Heading {...textStyle}>{parse(heading)}</Heading>}
                            {heading && <Heading {...markHeading}>{parse(mark)}</Heading>}
                            {text && <Heading {...textStyle}>{parse(text)}</Heading>}
                        </LeftBox>
                    </Col>
                    <Col lg={{span: 6, offset: 1}}>
                        <RightBox>
                            {desc && <Text {...descStyle}>{parse(desc)}</Text>}
                        </RightBox>
                    </Col>
                </Row>
            </Container>
        </AboutWrapper>
    )
}

AboutDesc.propTypes = {
    sectionStyle: PropTypes.object,
    sectionTitleStyle: PropTypes.object,
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object,
    descStyle: PropTypes.object,
}

AboutDesc.defaultProps = {
    sectionTitleStyle: {
        mb: '70px',
        responsive: {
            small: {
                mb: '30px'
            }
        }
    },
    headingStyle: {
        as: "h3",
        layout: "highlight",
        maxwidth: "100px",
        mb: "24px",
        responsive: {
            medium: {
                maxwidth: "100%"
            }
        }
    },
    markHeading: {
        layout: "highlight",
    },
    textStyle: {
        as: "h4",
        fontSize: "20px",
    },
    descStyle: {
        fontSize: "18px",
        lineHeight: 1.67,
        mb: "23px"
    },
    btnStyle: {
        varient: "texted",
        iconname: "far fa-long-arrow-right",
        hover: "false"
    }
}

export default AboutDesc;